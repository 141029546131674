import "./landing-page-minimal.scss";
import * as React from "react";
import { TypeLandingPageMinimalData } from "../utils/queries/landing_page_minimal";
import Helmet from "react-helmet";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import { TechDemoCalendar } from "../components/technical-demo-calendar";
import HubspotForm from "../components/hubspot-form";
import { PageTitle } from "../components/title/title";
import RichTextRender from "../utils/rich-text";
import usePreviewData from "../utils/usePreviewData";
import clsx from "classnames";

const MinimalLandingPage = ({ pageContext, location }: any) => {
  const result = usePreviewData(
    pageContext.data,
    "PrismicLandingPageMinimal",
    true
  ) as TypeLandingPageMinimalData;
  const showPage = true;

  const backgroundImage = result.featured_background_image
    ? result.featured_background_image.url
    : "";

  return showPage ? (
    <AmazeePage
      location={location}
      id="landing-page-minimal"
      classSet={`${result.template_type} ${result.page_classes}`}
    >
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
        />
        {result.canonical_url && (
          <link rel="canonical" href={result.canonical_url} />
        )}
      </Helmet>

      <PageTitle title={result.page_meta_title} />
      <AmazeeIoMetadata
        title={result.page_meta_title}
        page_meta_description={result.page_meta_description}
        page_meta_thumbnail={result.page_meta_thumbnail}
        location={location}
      />

      {result.template_type.includes("Landing-Dark-Header") && (
        <section
          id="main"
          className={clsx(
            "main-title-section main-title-section-dark",
            result.template_type.includes("full-icon") ? "full-icon" : ""
          )}
        >
          <div className="page-content container">
            <div className="row items-center">
              <div
                className={clsx(
                  "col-12",
                  result.template_type.includes("full-icon")
                    ? "col-md-8"
                    : "col-md-10"
                )}
              >
                {RichTextRender(result.landing_page_minimal_title)}
                <div className="subtitle">
                  {RichTextRender(result.landing_page_minimal_subtitle)}
                </div>
              </div>

              {result.page_icon && (
                <div
                  className={clsx(
                    "page_icon_wrapper hidden lg:block col-2",
                    result.template_type.includes("full-icon") ? "col-4" : ""
                  )}
                >
                  <div className="page_icon">
                    <img
                      src={result.page_icon.url}
                      alt={result.page_icon.alt}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      <div
        className={clsx("page-featured-hero")}
        data-color-override={result.link_color_override}
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <div className="container">
          <div className="row flex justify-center">
            <div className="col-12 col-lg-10">
              <div className="row justify-between">
                <div className="block lg:hidden col-12 mb-5">
                  <img
                    src={result.featured_hero_image.url}
                    alt={result.featured_hero_image.alt}
                  />
                </div>
                <div className="col-12 col-lg-6 col-md-12">
                  {RichTextRender(result.featured_hero_content)}
                </div>
                <div className="hidden lg:block col-5">
                  <img
                    src={result.featured_hero_image.url}
                    alt={result.featured_hero_image.alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content container">
        {result.template_type.indexOf("Landing-Dark-Header") === -1 && (
          <section id="main" className="main-title-section">
            {RichTextRender(result.landing_page_minimal_title)}
            {result.top_image.url && (
              <div className="top_image mb-10">
                <img src={result.top_image.url} alt={result.top_image.alt} />
              </div>
            )}
            <div className="subtitle">
              {RichTextRender(result.landing_page_minimal_subtitle)}
            </div>
            {result.page_icon && (
              <div className="page_icon">
                <img src={result.page_icon.url} alt={result.page_icon.alt} />
              </div>
            )}
          </section>
        )}
        {result.form_featured_image.url && (
          <img
            className="form-feature-image-mobile my-12 d-md-none"
            src={result.form_featured_image.url}
            alt={result.form_featured_image.alt}
          />
        )}

        {result.content_blocks && result.template_type !== "Video" && (
          <section id="featured-content" className="mt-8 mb-8">
            <div className="featured-title">
              {RichTextRender(result.featured_title)}
            </div>
            <div className="">
              <div
                className={`${
                  result.number_of_columns > 1
                    ? "md:grid grid-cols-" +
                      result.number_of_columns +
                      " md:gap-8 "
                    : ""
                }`}
              >
                {result.content_blocks.map((item, index) => (
                  <div key={index} className="featured-item">
                    {RichTextRender(item.content_block)}
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}

        {result.youtube_embed_url && (
          <section id="video" className="mb-16">
            <div className="">
              <iframe
                src={result.youtube_embed_url}
                frameborder="0"
                allow="autoplay; encrypted-media; fullscreen"
                allowfullscreen
                title="video"
              />{" "}
            </div>
          </section>
        )}

        {result.form_featured_description.text !== "" ? (
          <section id="form_section">
            {result.template_type == "Landing-Dark-Header advert" && (
              <div className="">
                <div className="form-feature-text mb-10">
                  {RichTextRender(result.mobile_form_featured_description)}
                </div>
              </div>
            )}
            <div className="form-inner md:flex">
              {result.template_type == "Form" && (
                <div className="md:hidden">
                  <div className="form-feature-text">
                    {RichTextRender(result.mobile_form_featured_description)}
                  </div>
                </div>
              )}

              <div className="hidden d-md-block left-side-inner">
                {result.template_type == "Default" && (
                  <img
                    className="form-feature-image mb-8"
                    src={result.form_featured_image.url}
                    alt={result.form_featured_image.alt}
                  />
                )}
                <div className="form-feature-text">
                  <div>
                    {result.template_type == "Video" && (
                      <div className="webinar">Webinar</div>
                    )}
                    {RichTextRender(result.form_featured_description)}
                  </div>

                  {result.presenters && result.template_type == "Form" && (
                    <section id="presentors" className="mt-16">
                      <div className="">
                        <div className="md:grid grid-cols-3 md:gap-8">
                          {result.presenters.map((presentor, index) => (
                            <div key={index} className="featured-item">
                              <div className="presentor-img">
                                <img
                                  src={presentor.headshot.url}
                                  alt={presentor.headshot.alt}
                                />
                              </div>
                              <div className="presentor-name">
                                {presentor.profile_link.document.data
                                  .urlslug && (
                                  <a
                                    target="_blank"
                                    href={`/about-us/people/${presentor.profile_link.document.data.urlslug}`}
                                  >
                                    {presentor.full_name}
                                  </a>
                                )}

                                {!presentor.profile_link.document.data
                                  .urlslug && (
                                  <span>{presentor.full_name}</span>
                                )}
                              </div>
                              <div className="presentor-position">
                                {presentor.position}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
              {result.template_type !== "Video" && result.hubspot_form_id && (
                <div>
                  {/*
                { result.template_type == "Form" &&
                   <img className="form-feature-image 0" src={result.form_featured_image.url} alt={result.form_featured_image.alt}/>
                }
                */}

                  {result.template_type == "Default" && (
                    <div className="form-container">
                      <h3>{RichTextRender(result.hubspot_form_title)}</h3>
                      <HubspotForm
                        formId={result.hubspot_form_id}
                        portalId={result.hubspot_form_portal_id}
                      />
                    </div>
                  )}

                  {result.template_type == "Form" && (
                    <div className="form-container">
                      <h3>{RichTextRender(result.hubspot_form_title)}</h3>
                      <HubspotForm
                        formId={result.hubspot_form_id}
                        portalId={result.hubspot_form_portal_id}
                      />
                    </div>
                  )}
                  {result.template_type.includes("Landing-Dark-Header") && (
                    <div className="form-container">
                      <h3>{RichTextRender(result.hubspot_form_title)}</h3>
                      <h4>{result.form_subtitle}</h4>
                      <HubspotForm
                        formId={result.hubspot_form_id}
                        portalId={result.hubspot_form_portal_id}
                      />
                    </div>
                  )}
                </div>
              )}
              {result.page_classes !== "wide-text" &&
                result.template_type == "Video" && (
                  <div className="video-presenters-container">
                    <section id="presentors" className="my-16">
                      <h4>Presented by:</h4>
                      <div className="">
                        <div className="md:grid grid-cols-2 md:gap-6">
                          {result.presenters.map((presentor, index) => (
                            <div key={index} className="featured-item">
                              <div className="presentor-img">
                                <img
                                  src={presentor.headshot.url}
                                  alt={presentor.headshot.alt}
                                />
                              </div>
                              <div className="presentor-name">
                                {/* {presentor.profile_link.document.data */}
                                {/* .urlslug && ( */}
                                {/* <a target="_blank" href={`/about-us/people/${presentor.profile_link.document.data.urlslug}`}> {presentor.full_name}</a> */}
                                <span>{presentor.full_name}</span>
                                {/* )} */}

                                {/* {!presentor.profile_link.document.data
                                  .urlslug && (
                                  <span>{presentor.full_name}</span>
                                )} */}
                              </div>
                              <div className="presentor-position">
                                {presentor.position}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  </div>
                )}
            </div>
          </section>
        ) : null}

        {result.template_type == "Video" && result.hubspot_form_portal_id && (
          <section id="form_section_wide">
            <div className="form-container">
              <h3>{RichTextRender(result.hubspot_form_title)}</h3>
              <HubspotForm
                formId={result.hubspot_form_id}
                portalId={result.hubspot_form_portal_id}
              />
            </div>
          </section>
        )}

        {result.cta_text && result.template_type == "Video" && (
          <section id="cta_section" className="mt-4">
            <div className="container col-md-8 cta-big">
              <h4>
                We are rethinking hosting entirely:
                <br />
                simpler, faster, and more secure.
              </h4>
              <a
                target="_blank"
                href="/sign-up"
                className="btn-boldish amazeei-button inverted inline-block width-auto mt-4"
              >
                Get Started
              </a>
            </div>
          </section>
        )}

        {result.template_type.includes("technical-demo-calendar") && (
          <TechDemoCalendar />
        )}
      </div>
    </AmazeePage>
  ) : (
    <div>You can’t access this secret page.</div>
  );
};

export default MinimalLandingPage;
